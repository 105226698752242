import React, { useContext } from "react";
import { Checkbox } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UPDATE_SHOP } from "@/graphql/Mutations/Shop";
import { useMutation, useQuery } from "@apollo/client";
import { SHOP_DETAILS } from "@/graphql/Queries";
import { IShop } from "@/graphql/Fragments/Shop";
import { PrefSectionHeader } from "./Page";
import { FeatureKey } from "@/graphql/Fragments/UserLicense";

export const AutoEstimationPrefs = (): JSX.Element | null => {
  const { t, currentShop, currentUser } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { data } = useQuery<{ shop: IShop }>(SHOP_DETAILS, {
    variables: { id: currentShop.id },
  });
  const [updateShop] = useMutation(UPDATE_SHOP);

  const hasAutoEstimationsFeature = currentUser.userProfile?.features?.some(
    f => f.featureKey === FeatureKey.AUTO_ESTIMATION
  );
  if (!hasAutoEstimationsFeature) {
    return null;
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <PrefSectionHeader className="page-header">
        <h2>{t("shop.preferences.autoEstimations.title")}</h2>
      </PrefSectionHeader>
      <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        {t("shop.preferences.autoEstimations.autoOrient.label")}
        <Checkbox
          className="qa-autoOrient"
          style={{ marginLeft: 10 }}
          checked={data?.shop?.autoOrient}
          onChange={() =>
            updateShop({
              variables: { input: { id: currentShop.id, autoOrient: !data?.shop?.autoOrient } },
            })
          }
        />
      </label>
    </div>
  );
};
