import React from "react";
import styled, { StyleMixins, Table } from "grabcad-ui-elements";
import { ShopOrderListColumns } from "./ShopOrderListColumns";
import { StatusesTable } from "./ShopStatusesTable";
import { AutoEstimationPrefs } from "./AutoEstimationPrefs";

const PreferencesWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
  > div {
    max-width: 800px;
    margin: 0 auto 40px auto;
  }
`;

export const PrefSectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5em !important;
  h2 {
    flex-grow: 1;
  }
`;

export const StyledPrefTable = styled(Table)`
  &.ui.table {
    ${StyleMixins.roundAndShadow}
    margin: 1em 0 1.5em 0 !important;
    tr {
      &:hover {
        background: #e0ecfc;
      }
      &.dragging {
        cursor: grabbing !important;
      }
      th.short {
        width: 1%;
        white-space: nowrap;
      }
      td {
        height: 50px;
        padding: 0 10px;
        &.flex {
          display: flex;
          align-items: center;
        }
        &.center {
          text-align: center;
        }
        .draggable-content {
          height: 50px;
          display: flex;
          align-items: center;
          cursor: grab;
          padding-left: 10px;
          position: relative;
          left: -10px;
          margin-right: -10px;
          .drag-button {
            width: 37px;
            position: relative;
            left: 3px;
            img {
              opacity: 0.1;
            }
          }
        }
      }
    }
  }

  .ui.disabled.checkbox label {
    opacity: 0.25;
  }
  .visibility {
    cursor: pointer;
    opacity: 0.5;
    padding: 10px;
    margin: 0 auto;
    position: relative;
    &:hover:not(.disabled) {
      opacity: 1;
    }
    &.disabled {
      opacity: 0.1;
    }
  }

  /* MYSTERY MEAT :u( */
  tr:not(:hover) {
    .visibility:not(.is-hidden),
    .icon.trash {
      display: none;
    }
  }
`;

export const ScreensShopPreferencesPage = (): JSX.Element => (
  <PreferencesWrapper>
    <div>
      <AutoEstimationPrefs />
      <StatusesTable />
      <ShopOrderListColumns />
    </div>
  </PreferencesWrapper>
);

ScreensShopPreferencesPage.displayName = "ScreensShopPreferencesPage";
