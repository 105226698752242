import { ApolloConsumer } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import React, { useContext } from "react";
import { Loader } from "grabcad-ui-elements";
import { SHOP_ORDER_LIST_COLUMNS } from "@/graphql/Queries/Shop";
import { ShopColumnsTable } from "./ShopColumnsTable";
import { IShopPreferences } from "@/graphql/Fragments/Shop";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { PrefSectionHeader } from "../Page";

export interface IShopColumn {
  name: string;
  isVisible: boolean;
  isMandatory: boolean;
}

export const ShopOrderListColumns = (): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);

  return (
    currentShop && (
      <Query<{ shop?: { shopPreferences: IShopPreferences } }>
        fetchPolicy="network-only"
        query={SHOP_ORDER_LIST_COLUMNS}
        variables={{ id: currentShop.id }}
      >
        {orderListColumnsResult => {
          if (
            orderListColumnsResult.error ||
            orderListColumnsResult.loading ||
            !orderListColumnsResult.data
          ) {
            return <Loader active={true} />;
          }
          return (
            <ApolloConsumer>
              {client => (
                <>
                  <PrefSectionHeader>
                    <h2>{t("shop.preferences.columns.title")}</h2>
                  </PrefSectionHeader>
                  <ShopColumnsTable
                    shopColumnsPreferences={
                      orderListColumnsResult.data?.shop
                        ? orderListColumnsResult.data.shop.shopPreferences.columns
                        : []
                    }
                    client={client}
                  />
                </>
              )}
            </ApolloConsumer>
          );
        }}
      </Query>
    )
  );
};
ShopOrderListColumns.displayName = "ShopOrderListColumns";
