import gql from "graphql-tag";
import { ShopFragments } from "@/graphql/Fragments";

export const CREATE_SHOP = gql`
  mutation createShopMutation($input: ShopCreateInput!) {
    createShop(input: $input) {
      ...shopFields
      ...shopLogoFields
    }
  }
  ${ShopFragments.shop}
  ${ShopFragments.shopLogo}
`;

export const UPDATE_SHOP = gql`
  mutation updateShopMutation($input: ShopUpdateInput!) {
    updateShop(input: $input) {
      ...shopFields
      ...shopLogoFields
    }
  }
  ${ShopFragments.shop}
  ${ShopFragments.shopLogo}
`;

export const DELETE_SHOP = gql`
  mutation deleteShopMutation($id: Int!) {
    deleteShop(id: $id) {
      id
    }
  }
`;

export const UPDATE_SHOP_COLUMNS = gql`
  mutation updateShopOrderListColumnsMutation($shopId: Int!, $columns: [String!]!) {
    updateShopOrderListColumns(shopId: $shopId, columns: $columns) {
      id
      shopPreferences {
        columns
      }
    }
  }
`;
