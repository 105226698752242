import React, { useRef, useContext } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes, IShopColumn } from "./ShopColumnsTable";
import { Image, TableCell } from "grabcad-ui-elements";
import visible from "../../../../assets/icons/visible.svg";
import hidden from "../../../../assets/icons/hidden.svg";
import dragIcon from "../../../../assets/icons/drag-active.svg";
import { ApplicationContext } from "../../../../components/ApplicationProvider";
import classnames from "classnames";
import ReactGA from "react-ga";

export interface IShopColumnRowProps {
  id: any;
  index: number;
  columnItem: IShopColumn;
  moveRow: (id: string, to: number) => void;
  dropRow: () => void;
  findRow: (id: string) => { index: number };
  resetColumnsList: () => void;
  toggleColumnVisibility: (index: number) => void;
}

interface IShopColumnRowDragItem {
  index: number;
  id: string;
  type: string;
}

export const ShopColumnRow = ({
  id,
  columnItem,
  index,
  moveRow,
  dropRow,
  findRow,
  toggleColumnVisibility,
  resetColumnsList,
}: IShopColumnRowProps): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const ref = useRef<HTMLTableRowElement>(null);
  const [, drop] = useDrop({
    accept: ItemTypes.COLUMN_ROW,
    canDrop: () => false,
    hover({ id: draggedId }: IShopColumnRowDragItem) {
      if (draggedId !== id) {
        const { index: overIndex } = findRow(id);
        moveRow(draggedId, overIndex);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.COLUMN_ROW,
    item: { id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        dropRow();
      } else {
        resetColumnsList();
      }
    },
  });

  drag(drop(ref));
  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0 : 1 }} className="qa-columnRow">
      <TableCell>
        <div className="draggable-content">
          <div className="drag-button">
            <Image src={dragIcon} />
          </div>
          <div className="qa-columnRow-name">{t(`order.list.table.${columnItem.name}`)}</div>
        </div>
      </TableCell>
      <TableCell>
        <Image
          className={classnames("visibility qa-columnRow-visibility", {
            disabled: columnItem.isMandatory,
            "is-hidden": !columnItem.isVisible,
          })}
          src={columnItem.isVisible ? visible : hidden}
          onClick={() => {
            if (!columnItem.isMandatory) {
              toggleColumnVisibility(index);
              ReactGA.event({
                category: "GcShop Preferences",
                action: "Customized column visibility",
                label: `Shop ${currentShop?.id}`,
              });
            }
          }}
        />
      </TableCell>
    </tr>
  );
};
