import gql from "graphql-tag";
import { IShopMachine } from "./ShopMachine";
import { IShopTechnology } from "./ShopTechnology";
import { Permission } from "@/utils/Permission";
import { IAsset } from "@/graphql/Mutations/Upload";
import { IShopStatus } from "./ShopStatus";
import { IImage } from "./Image";

export enum OrderListColumn {
  Id = "id",
  Name = "name",
  Requestor = "requestor",
  DateCreated = "dateCreated",
  NeedByDate = "needByDate",
  DueDate = "dueDate",
  Status = "status",
  ShopStatus = "shopStatus",
  Price = "price",
  ProjectCode = "projectCode",
  Technology = "technology",
  Material = "material",
  NumberOfItems = "orderItems",
  TotalPartsQuantity = "totalPartsQuantity",
  Operator = "operator",
}

export const mandatoryColumns = [OrderListColumn.Id, OrderListColumn.Name, OrderListColumn.Status];
export const allColumns = [
  OrderListColumn.Id,
  OrderListColumn.Name,
  OrderListColumn.Requestor,
  OrderListColumn.DateCreated,
  OrderListColumn.NeedByDate,
  OrderListColumn.DueDate,
  OrderListColumn.Status,
  OrderListColumn.Price,
  OrderListColumn.ProjectCode,
  OrderListColumn.Technology,
  OrderListColumn.Material,
  OrderListColumn.NumberOfItems,
  OrderListColumn.TotalPartsQuantity,
  OrderListColumn.Operator,
];
export const defaultColumns = [
  OrderListColumn.Id,
  OrderListColumn.Name,
  OrderListColumn.Requestor,
  OrderListColumn.DateCreated,
  OrderListColumn.NeedByDate,
  OrderListColumn.DueDate,
  OrderListColumn.Status,
  OrderListColumn.Price,
];

export const ShopFragments = {
  shop: gql`
    fragment shopFields on Shop {
      id
      name
      currencyCode
      currencyDenominator
      preferredUnits
      state
      permissions
      autoOrient
      __typename
    }
  `,
  shopLogo: gql`
    fragment shopLogoFields on Shop {
      logo {
        id
        small {
          id
          location
        }
        medium {
          id
          location
        }
      }
    }
  `,
};

export interface IShop {
  id: number;
  name: string | undefined;
  logo?: Partial<IShopLogo>;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  countryCode?: string;
  regionCode?: string;
  postalCode?: string;
  currencyCode: string;
  currencyDenominator: number;
  preferredUnits: string;
  state: string;
  shopTechnologies?: IShopTechnology[];

  // This is only used by SHOP_DETAILS to check onboarding phase.
  // TODO: Pass as 2 booleans: `hasMachines` & `hasMaterials`.
  shopMachines?: IShopMachine[];

  shopStatuses: IShopStatus[];
  logoImageId: number; // Feels like this can removed?
  permissions: Permission[];
  shopPreferences: IShopPreferences;
  operators: IShopOperator[];
  autoOrient: boolean;
  dateDeleted: Date | null;
  __typename?: string;
}

export interface IShopLogo extends IImage {
  id: number;
  original: IAsset;
  small: IAsset;
  medium: IAsset;
}

export enum ShopState {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
  SAMPLE = "SAMPLE",
}

export interface IShopPreferences {
  columns: string[];
}

export interface IShopOperator {
  id?: number;
  name?: string;
  email?: string;
}

export interface IShopTechnologiesWithMachines {
  shop: { shopTechnologies: IShopTechnology[] };
}

export interface IShopTechnologiesWithMachineImages {
  shop: {
    id: number;
    shopTechnologies: {
      id: IShopTechnology["id"];
      shopMachines: Pick<IShopTechnology["shopMachines"][number], "id" | "image">[];
    }[];
  };
}

export interface IShopTechnologiesWithDeletedMachines {
  shop: {
    id: number;
    shopTechnologies: Pick<IShopTechnology, "id" | "shopMachines">[];
  };
}
