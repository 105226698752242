import React, { useContext, useState, useEffect } from "react";
import { ROUTES } from "@/shopConstants";
import {
  FiltersPopup,
  DateFilterType,
  DateFilterRangeType,
  CustomDateRange,
  DATE_CREATED,
  NEED_BY_DATE,
  DUE_DATE,
  LAST_24_HOURS,
  LAST_7_DAYS,
  LAST_30_DAYS,
  CUSTOM_RANGE,
} from "@/components/Order/List/FiltersPopup";
import { withRouter } from "react-router";
import { ApplicationContext } from "@/components/ApplicationProvider";
import styled, { Button, Header } from "grabcad-ui-elements";
import { IOrderFilters, OrderList } from "@/components/Order/List/List";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { PROFILE } from "@/graphql/Queries";
import { ORDERS_LIST, IOrdersListData } from "@/graphql/Queries/Order";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { useQuery, useMutation } from "@apollo/client";
import { OrderListColumn, IShopPreferences } from "@/graphql/Fragments/Shop";
import { SearchBox } from "@/components/Order/List/SearchBox";
import { SHOP_ORDER_LIST_COLUMNS } from "@/graphql/Queries/Shop";
import moment from "moment";
import ReactGA from "react-ga";
import { IProfileResponse } from "@/graphql/Fragments/User";
import { UpdatePreferenceResult, UPDATE_PREFERENCE } from "@/graphql/Mutations/User";
import { updatePreferencesCache } from "@/utils/preferences";
import { useShopTechnologies } from "@/utils/queryHooks";

const OrdersListScreen = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .filtersRow {
    margin: 0.5rem 0 15px 0;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    > .filters {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }
  .listRow {
    min-height: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
    flex: 1 1 100%;
  }
`;

export const ORDERS_COUNT_PER_PAGE_PREF_KEY = "orders-per-page";

interface IOrdersQueryVariables extends IOrderFilters {
  shopId: number;
  count?: number;
  page?: number;
}

export const ScreensShopOrderList = withRouter(({ history }) => {
  const { currentShop, t, getOrderListStateByShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { shopTechnologies } = useShopTechnologies();

  const orderListState = getOrderListStateByShop();
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>(
    orderListState.filters.shopStatuses
  );
  const [selectedShopTechnologyId, setSelectedShopTechnologyId] = useState<number | null>(
    orderListState.filters.technology
  );
  const [searchTerms, setSearchTerms] = useState<string | null>(
    orderListState.searchTerms ? orderListState.searchTerms : null
  );
  const [dateFilterType, setDateFilterType] = useState<DateFilterType>(DATE_CREATED);
  const [dateFilterRange, setDateFilterRange] = useState<DateFilterRangeType>(null);
  const [customDateRange, setCustomnDateRange] = useState<CustomDateRange>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    // Store filters in context when they change
    orderListState.filters.shopStatuses = selectedStatuses;
    orderListState.filters.technology = selectedShopTechnologyId;
    orderListState.searchTerms = searchTerms;
  }, [selectedStatuses, selectedShopTechnologyId, searchTerms]);

  const selectedTech =
    selectedShopTechnologyId !== null
      ? shopTechnologies.find((tech: IShopTechnology) => tech.id === selectedShopTechnologyId)
      : undefined;

  const orderFilters: IOrderFilters = {
    shopTechnologyId: selectedShopTechnologyId || undefined,
    shopStatuses: selectedStatuses.length ? selectedStatuses : undefined,
    searchTerms: searchTerms || undefined,
  };

  const DAY = 1000 * 60 * 60 * 24;
  const getStartDate = (): Date | undefined => {
    const now = new Date().getTime();
    if (dateFilterRange === LAST_24_HOURS) {
      return new Date(now - DAY);
    } else if (dateFilterRange === LAST_7_DAYS) {
      return new Date(now - DAY * 7);
    } else if (dateFilterRange === LAST_30_DAYS) {
      return new Date(now - DAY * 30);
    } else if (dateFilterRange === CUSTOM_RANGE) {
      return customDateRange.startDate ? customDateRange.startDate.toDate() : undefined;
    }
  };
  const getEndDate = (): Date | undefined => {
    if (dateFilterRange === CUSTOM_RANGE) {
      // Adding 1 day to endDate because react-dates DateRange Picker returns local midnight of endDate, effectively excluding it from the range
      // NB: startDate is inclusive, endDate is exclusive: https://github.com/GrabCAD/shop-server/blob/d255cc1ef3a6e515a0e55994a63bd8ed1b3a7647/src/services/Order.ts#L217
      return customDateRange.endDate
        ? moment(customDateRange.endDate).add(1, "day").toDate()
        : undefined;
    }
  };

  // Make start and end date stateful so relative ranges do not cause infinite rerender as time keeps on tickin'
  // An alternative would be to round `now`, but this could still cause errant refetches when crossing over round boundary.
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  useEffect(() => {
    setStartDate(getStartDate());
    setEndDate(getEndDate());
  }, [dateFilterRange, customDateRange.startDate, customDateRange.endDate]);

  if (dateFilterRange) {
    if (dateFilterType === DATE_CREATED) {
      orderFilters.dateCreatedStart = startDate;
      orderFilters.dateCreatedEnd = endDate;
    } else if (dateFilterType === NEED_BY_DATE) {
      orderFilters.needByDateStart = startDate;
      orderFilters.needByDateEnd = endDate;
    } else if (dateFilterType === DUE_DATE) {
      orderFilters.dueDateStart = startDate;
      orderFilters.dueDateEnd = endDate;
    }
  }

  // Pagination
  const PAGE_COUNT_DEFAULT = 100;
  const [count, setCount] = useState<number>(PAGE_COUNT_DEFAULT);
  const [page, setPage] = useState<number>(1);

  // Pagination count is stored as user preference
  let countPrefNum: number | undefined;
  const { data: profileData } = useQuery<IProfileResponse>(PROFILE);

  useEffect(() => {
    if (countPrefNum) {
      setCount(countPrefNum);
    }
  }, [countPrefNum]);

  const [updatePreference] = useMutation<UpdatePreferenceResult>(UPDATE_PREFERENCE, {
    update: updatePreferencesCache,
  });

  const countPref = profileData?.userProfile?.preferences?.find(
    pref => pref.key === ORDERS_COUNT_PER_PAGE_PREF_KEY
  )?.value;
  if (countPref && typeof countPref === "object" && countPref[0]) {
    // Why is this always an array? Why does it have to be a string? EG: ["25"]
    // @grabcad/preferences-client seems a bit opinionated
    countPrefNum = +countPref[0];
  }

  const { loading, data } = useQuery<IOrdersListData, IOrdersQueryVariables>(ORDERS_LIST, {
    fetchPolicy: "network-only",
    variables: {
      shopId: currentShop.id,
      count,
      page,
      ...orderFilters,
    },
  });

  useEffect(() => {
    if (
      data?.ordersByShopPaginated.filteredCount &&
      page > Math.ceil(data.ordersByShopPaginated.filteredCount / count)
    ) {
      // User has changed order-count-per-page to a value out of range of the currently selected page.
      // Show 'em the highest page possible page:
      setPage(Math.ceil(data.ordersByShopPaginated.filteredCount / count));
    }
  }, [count, data?.ordersByShopPaginated.filteredCount]);

  const { data: orderListColumnsResult } = useQuery<{
    shop?: { shopPreferences: IShopPreferences };
  }>(SHOP_ORDER_LIST_COLUMNS, {
    variables: { id: currentShop.id },
  });

  const orderListColumns: OrderListColumn[] = orderListColumnsResult?.shop
    ? (orderListColumnsResult.shop.shopPreferences.columns.filter(string =>
        Object.values(OrderListColumn).includes(string as OrderListColumn)
      ) as OrderListColumn[])
    : [];

  return (
    <OrdersListScreen>
      <Header as="h2" className="page-header">
        {t("order.list.title")}
      </Header>

      <div className="filtersRow">
        <SearchBox searchTerms={searchTerms} onTermsSearched={setSearchTerms} loading={loading} />
        <div className="filters">
          <FiltersPopup
            selectedShopTechnologyId={selectedShopTechnologyId}
            onTechnologySelected={setSelectedShopTechnologyId}
            selectedStatuses={selectedStatuses}
            onStatusesSelected={setSelectedStatuses}
            dateFilterType={dateFilterType}
            onDateFilterTypeSelected={setDateFilterType}
            dateFilterRange={dateFilterRange}
            onDateFilterRangeSelected={setDateFilterRange}
            customDateRange={customDateRange}
            onCustomnDateRangeSelected={setCustomnDateRange}
          />
        </div>
        <UiCan do={Permission.CREATE_ORDER} on={currentShop}>
          <Button
            id="qa-button-newOrder"
            primary
            content={t("order.list.newOrder")}
            onClick={() => {
              history.push(ROUTES.SHOP(currentShop.id).ORDER.NEW);
              ReactGA.event({
                category: "GcShop Order",
                action: "Create New Order",
                label: `Shop ${currentShop.id}`,
              });
            }}
          />
        </UiCan>
      </div>

      <div className="listRow">
        {loading ? (
          <OrderList loading={true} count={count} />
        ) : (
          <OrderList
            orders={data?.ordersByShopPaginated.orders}
            technologyDisplayName={selectedTech?.appTechnology.displayName}
            orderListColumns={orderListColumns}
            dateFilterRange={dateFilterRange}
            shopTechnologies={shopTechnologies}
            orderFilters={orderFilters}
            count={count}
            page={page}
            updatePagination={pagination => {
              if (count !== pagination.count) {
                updatePreference({
                  variables: {
                    key: ORDERS_COUNT_PER_PAGE_PREF_KEY,
                    value: pagination.count.toString(),
                  },
                });
              }
              setCount(pagination.count);
              setPage(pagination.page);
            }}
            filteredCount={data?.ordersByShopPaginated.filteredCount}
          />
        )}
      </div>
    </OrdersListScreen>
  );
});
ScreensShopOrderList.displayName = "ScreensShopOrderList";
